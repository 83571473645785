<template>
  <div>
    <b-row>
      <b-col sm="8">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="12" y="4" width="3" height="13" rx="1.5"/><rect fill="#000" opacity=".3" x="7" y="9" width="3" height="8" rx="1.5"/><path d="M5 19h15a1 1 0 010 2H4a1 1 0 01-1-1V4a1 1 0 112 0v15z" fill="#000" fill-rule="nonzero"/><rect fill="#000" opacity=".3" x="17" y="11" width="3" height="6" rx="1.5"/></g></svg>
              Statistik
            </h3>
          </template>
          <template v-slot:body>
            <div class="kt-widget17">
              <div class="kt-widget17__stats" style="margin: auto">
                <div class="kt-widget17__items">
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--warning'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M22 15v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4h4.28l.543 1.632A2 2 0 008.721 18h6.661a2 2 0 001.789-1.106L18.118 15H22z" fill="#000"/><path d="M2.563 13l3.364-5.98A2 2 0 017.67 6h8.66a2 2 0 011.743 1.02L21.437 13h-3.319a2 2 0 00-1.789 1.106L15.382 16H8.721l-.544-1.632A2 2 0 006.279 13H2.563z" fill="#000" opacity=".3"/></g></svg></span>
                    <span class="kt-widget17__subtitle"> All Order </span>
                    <span class="kt-widget17__desc"> 72 Order </span>
                  </div>
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--danger'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M6 2h12a1 1 0 011 1v9a1 1 0 01-1 1H6a1 1 0 01-1-1V3a1 1 0 011-1zm1.5 3a.5.5 0 000 1h6a.5.5 0 100-1h-6zm0 2a.5.5 0 000 1h3a.5.5 0 100-1h-3z" fill="#000" opacity=".3"/><path d="M3.793 6.573L12 12.5l8.207-5.927a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405z" fill="#000"/></g></svg></span>
                    <span class="kt-widget17__subtitle"> Active Order </span>
                    <span class="kt-widget17__desc"> 34 order </span>
                  </div>
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--danger'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 17v-1a1 1 0 00-2 0H0a2 2 0 114 0v1h4a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3a1 1 0 011-1z" fill="#000" opacity=".3"/><path d="M12.984 18A7.5 7.5 0 004 11.15V7a2 2 0 012-2h14a2 2 0 012 2v9a2 2 0 01-2 2h-7.016zm6.16-11.16L13 10.148 6.856 6.84a.75.75 0 10-.712 1.32l6.5 3.5c.222.12.49.12.712 0l6.5-3.5a.75.75 0 00-.712-1.32z" fill="#000"/></g></svg></span>
                    <span class="kt-widget17__subtitle"> Settled Order </span>
                    <span class="kt-widget17__desc"> 34 Order </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </RWCard>
      </b-col>
      <b-col sm="4">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="20.5" cy="12.5" r="1.5"/><rect fill="#000" opacity=".3" transform="rotate(-15 12 6.5)" x="3" y="3" width="18" height="7" rx="1"/><path d="M22 9.337a3.5 3.5 0 100 6.326V18a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v3.337z" fill="#000"/></g></svg>
              Balance
            </h3>
          </template>
          <template v-slot:body>
            <div class="kt-widget4">
              <div class="kt-widget4__item">
                <div class="kt-widget4__info">
                  <a href="#" class="kt-widget4__username">Pending Komisi</a>
                  <p class="kt-widget4__text">Subtitle Pending Komisi</p>
                </div>
                <span class="kt-font-warning kt-font-bold amount"
                  >Rp420.000</span
                >
              </div>
              <div class="kt-widget4__item">
                <div class="kt-widget4__info">
                  <a href="#" class="kt-widget4__username">Saldo Tersedia</a>
                  <p class="kt-widget4__text">Subtitle Saldo Tersedia</p>
                </div>
                <span class="kt-font-success kt-font-bold amount"
                  >Rp4.000.000</span
                >
              </div>
              <div class="kt-widget4__item">
                <div class="kt-widget4__info">
                  <a href="#" class="kt-widget4__username">Total Penarikan</a>
                  <p class="kt-widget4__text">Subtitle Total Penarikan</p>
                </div>
                <span class="kt-font-danger kt-font-bold amount"
                  >Rp7.000.000</span
                >
              </div>
            </div>
          </template>
        </RWCard>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="8">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M9.879 12.707l-2.122 2.121a1 1 0 101.415 1.415l2.12-2.122.708.707a2 2 0 010 2.829l-2.828 2.828a2 2 0 01-2.829 0l-2.828-2.828a2 2 0 010-2.829L6.343 12a2 2 0 012.829 0l.707.707z" fill="#000" opacity=".3"/><path d="M14.121 11.293l2.122-2.121a1 1 0 00-1.415-1.415l-2.12 2.122L12 9.172a2 2 0 010-2.829l2.828-2.828a2 2 0 012.829 0l2.828 2.828a2 2 0 010 2.829L17.657 12a2 2 0 01-2.829 0l-.707-.707z" fill="#000"/><path d="M4.222 4.222a1 1 0 011.414 0L7.05 5.636A1 1 0 115.636 7.05L4.222 5.636a1 1 0 010-1.414zm5.121-2.707a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM1.686 9.172a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM19.657 19.657a1 1 0 000-1.414l-1.414-1.415a1 1 0 00-1.415 1.415l1.415 1.414a1 1 0 001.414 0zm2.707-5.121a1 1 0 00-1-1h-2a1 1 0 000 2h2a1 1 0 001-1zm-7.657 7.656a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1z" fill="#000" opacity=".3"/></g></svg>
              Link Utama Afiliasi
            </h3>
          </template>
          <template v-slot:body>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.
            </p>
            <b-row>
              <b-col sm="7">
                <b-form-group>
                  <b-form-input v-model="link" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="5">
                <b-button variant="outline-success"
                  >Link Spesifik Produk</b-button
                >
                &nbsp;
                <b-button variant="outline-success">Banner</b-button>
              </b-col>
            </b-row>
          </template>
        </RWCard>
      </b-col>
      <b-col sm="4">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 6h16a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1zm1 5h5a1 1 0 011 1v7a1 1 0 01-1 1H5a1 1 0 01-1-1v-7a1 1 0 011-1zm9 0h5a1 1 0 011 1v7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-7a1 1 0 011-1z" fill="#000"/><path d="M14.445 2.168a1 1 0 011.11 1.664L12 6.202l-3.555-2.37a1 1 0 011.11-1.664L12 3.798l2.445-1.63z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
              Promo Khusus (v.1.0)
            </h3>
          </template>
          <template v-slot:body>
            <p>Komisi 70% Terima Jadi Selama Mei 2020</p>
          </template>
        </RWCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
// import Widget17 from "@/views/partials/widgets/Widget17.vue";
export default {
  components: {
    RWCard
  },
  data() {
    return {
      link: "https://afiliasi.rumahweb.com/ref/99999"
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Afiliasi" }]);
  }
};
</script>

<style scoped>
.amount {
  font-size: 1.2em;
}
</style>
